<template>
  <div class="customerOrders container">
	<p class="h6 text-center text-primary"><b>Customer Order Volume in last 1 Year</b></p>    
	<br>

	<CCard :color="'secondary'">
		<CCardBody>
			<form class="form-inline">
				<div class="form-group">
					<label class="font-weight-bold mr-2" for="fromdate">State:</label>
					<select class="col-sm-6 form-control form-select mr-3" v-model="stateSel">
						<option v-for="state in stateList" v-bind:value="state">
							{{ state }}
						</option>
					</select>

					<CButton color="dark" v-on:click.prevent="serverCall('getCustomerOrdersVolume')">Fetch Data</CButton>
				</div>

				<div>
					<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
					<scale-loader :loading="loading"></scale-loader>
				</div>

			</form>	
		</CCardBody>
	</CCard>

	<p class="text-danger" align="left">{{alert}}</p>

	<div>
		<vue-good-table
			ref="reportTable"
			:columns="columns"
			:rows="rows"
			:line-numbers="true"
			:pagination-options="pagination_options" 
			:row-style-class="rowStyleClassFn"
			:sort-options="{enabled: true}"
			styleClass="vgt-table condensed"
		>
		</vue-good-table>
	</div>

	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import ScaleLoader from "@/views/utils/ScaleLoader";
import { firebaseAuth } from '../../firebaseConfig.js';
var FileSaver = require('file-saver')
import Helper from "../utils/Helper.vue"

export default {
	name: 'customerOrders',

	mixins: [
		Helper
	],

    components: {
        ScaleLoader,
	},
		
	data () {

		return {
			orgId: null,

			columns: [],
			rows: [],
			dataList: [],

			reportName: "Value",
			reportLongName: "",
			reportData: "",

			alert: null,
			loading: false,

			stateList: ["ALL", "Andhra Pradesh","Andaman and Nicobar Islands","Arunachal Pradesh","Assam","Bihar",
						"Chandigarh","Chhattisgarh","Dadar and Nagar Haveli","Daman and Diu","Delhi","Lakshadweep",
						"Puducherry","Goa","Gujarat","Haryana","Himachal Pradesh","Jammu and Kashmir","Jharkhand",
						"Karnataka","Kerala","Madhya Pradesh","Maharashtra","Manipur","Meghalaya","Mizoram",
						"Nagaland","Odisha","Punjab","Rajasthan","Sikkim","Tamil Nadu","Telangana","Tripura",
						"Uttar Pradesh","Uttarakhand","West Bengal"],
			stateSel: "ALL",

            pagination_options: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [10, 20, 30, 40],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
			},

		};
	},

	computed: {
	},

	mounted(){
		this.orgId = this.$route.params['orgId']
	},

	methods: {
        formatNumberFn: function(value) {
          return value.toFixed(2)
        },
		
		rowStyleClassFn(row) {
			if (row.color == 1){
				return 'active'
			}
		},

        serverCall(name) {
            var self = this
			self.alert = null

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getCustomerOrdersVolume":
								self.getCustomerOrdersVolume(idToken)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})
		},
		

        // Get Customer Orders
        getCustomerOrdersVolume(authToken) {
            var self = this
			self.alert = null

            if (!this.stateSel){
                self.alert = "State is not selected."
                return
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Customer Orders Data..."

			var postJson = {
				'state': this.stateSel,
				'Distributors': 'False'
			}

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetCustomerOrdersVolume/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("getCustomerOrdersVolume - error:" + response.data.err)
					}
					else {
						self.columns = response.data.columns
						self.rows = response.data.dataList
						//self.reportLongName = self.reportName + " - " + fdStr + " To " + tdStr
						//self.reportData = response.data.reportData
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},


		// downloadReport() {
		// 	if (this.reportData==[]){
		// 		alert("No report data.")
		// 		return
		// 	}
		// 	let blob = new Blob([this.base64toBlob(this.reportData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
		// 	FileSaver.saveAs(blob, this.reportLongName + '.xlsx');
		// },

	},

}
</script>

<style>
	.blue {
		background-color: #F0F8FF;
	}

	.yellow {
		background-color: #90EE90;
	}

	.active {
		color : green;
	}
</style>

